import React, { Fragment, memo } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Row, Col, Container } from "reactstrap"
import clsx from "clsx"
import * as classes from "./styles.module.scss"
import { SectionMore } from "../ui"

interface RelatedSearchProps {
  listItem: SectionMore[]
}

const RelatedSearch = memo((props: RelatedSearchProps) => {
  const { listItem } = props

  return (
    <Container>
      <Row>
        <Col lg="1" />
        <Col>
          <h3 className={classes.title}>Related Products</h3>
        </Col>
        <Col lg="1" />
      </Row>
      <Row>
        <Col lg="1" />
        {listItem?.map((item, index) => (
          <Fragment key={index}>
            <Col lg="2" md="4" xs="6" className="mt-3">
              <GatsbyImage
                className={clsx("rounded", classes.image)}
                alt={item?.image?.alt}
                image={getImage(item?.image?.gatsbyImageData)}
              />
              <h4 className={classes.itemTitle}>{item?.title}</h4>
            </Col>
            {index === 2 && <Col md="2" className={classes.breakItem}></Col>}
            {index === 3 && (
              <Col xs="3" className={classes.breakItemMobile}></Col>
            )}
          </Fragment>
        ))}
      </Row>
    </Container>
  )
})

export default RelatedSearch
