import React, { memo } from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Card, Row, Col, Container, Button } from "reactstrap"
import clsx from "clsx"
import * as classes from "../../styles.module.scss"
import RelatedSearch from "../../../../components/RelatedSearch"
import {
  HomepageImage,
  SectionCard,
  SectionMore,
} from "../../../../components/ui"
import { graphql } from "gatsby"

export interface MainServiceSection4Props {
  id: string
  title: string
  description: string
  heading1: string
  logo: HomepageImage
  heading2: string
  section4Card: SectionCard[]
  section4More: SectionMore[]
}

const MainServiceSection4 = memo((props: MainServiceSection4Props) => {
  return (
    <div
      id="section4"
      className={clsx("projects-3", classes.section3)}
      data-background-color="black"
    >
      <Row className={classes.titleSec3Wrapper}>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h2
            className={clsx("title", classes.titleSec3, classes.uppercaseWord)}
          >
            {props?.title}
          </h2>
          <h4
            className={clsx("category text-muted mb-3", classes.subTitleSec3)}
          >
            {props?.description}
          </h4>
        </Col>
      </Row>
      <Container className="mt-4">
        <Row>
          <Col className="ml-auto mr-auto text-center" md="12">
            <h5 className={clsx("text-muted", classes.descriptionSec3)}>
              {props?.heading1}
            </h5>
          </Col>
        </Row>
        <Row className={classes.logoSec3Wrapper}>
          <GatsbyImage
            className={clsx("rounded")}
            alt={props?.logo?.alt}
            image={getImage(props?.logo?.gatsbyImageData)}
          />
        </Row>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="12">
            <h5 className={clsx("text-muted", classes.subTitleSec3)}>
              {props?.heading2}
            </h5>
          </Col>
        </Row>

        <div className={clsx("section-story-overview", classes.row)}>
          <Row>
            <Col md="6">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[0]?.image?.alt}
                    image={getImage(
                      props?.section4Card[0]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2 pb-4")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[0]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[0]?.description}
                  </p>
                </div>
              </Card>
            </Col>
            <Col md="6">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[1]?.image?.alt}
                    image={getImage(
                      props?.section4Card[1]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2 pb-4")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[1]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[1]?.description}
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </div>

        <div className={clsx("section-story-overview", classes.row)}>
          <Row>
            <Col md="6">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[2]?.image?.alt}
                    image={getImage(
                      props?.section4Card[2]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2 pb-4")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[2]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[2]?.description}
                  </p>
                </div>
              </Card>
            </Col>
            <Col md="6">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[3]?.image?.alt}
                    image={getImage(
                      props?.section4Card[3]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2 pb-4")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[3]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[3]?.description}
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </div>

        <div className={clsx("section-story-overview", classes.row)}>
          <Row>
            <Col md="6">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[4]?.image?.alt}
                    image={getImage(
                      props?.section4Card[4]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2 pb-4")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[4]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[4]?.description}
                  </p>
                </div>
              </Card>
            </Col>
            <Col md="6">
              <Card className={clsx("mb-0", classes.cardSec3)}>
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImage)}
                    alt={props?.section4Card[5]?.image?.alt}
                    image={getImage(
                      props?.section4Card[5]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div className={clsx("info text-center pt-2 pb-4")}>
                  <h4 className={clsx("info-title", classes.infoTitleSec3)}>
                    {props?.section4Card[5]?.title}
                  </h4>
                  <p
                    className={clsx("description", classes.cardDescriptionSec3)}
                  >
                    {props?.section4Card[5]?.description}
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <Button className="px-5 " color="primary" href="/enquiry">
            <h4 className="m-0">Enquiry</h4>
          </Button>
      </Container>
      
      <RelatedSearch listItem={props?.section4More} />

      <div id="section5" />
    </div>
  )
})

export default MainServiceSection4

export const query = graphql`
  fragment MainServiceSection4Content on MainServiceSection4 {
    id
    title
    description
    heading1
    logo {
      alt
      id
      gatsbyImageData
    }
    heading2
    section4Card {
      id
      image {
        alt
        id
        gatsbyImageData
      }
      title
      description
    }
    section4More {
      id
      image {
        alt
        id
        gatsbyImageData
      }
      title
    }
  }
`
