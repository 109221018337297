// extracted by mini-css-extract-plugin
export var avatar = "styles-module--avatar--b-dlY";
export var card = "styles-module--card--7F+vH";
export var cardBodyItem = "styles-module--cardBodyItem--H5pER";
export var cardContainer = "styles-module--cardContainer--u+KC0";
export var cardContainerSec2 = "styles-module--cardContainerSec2--Oj-Dl";
export var cardContent = "styles-module--cardContent--ePWj2";
export var cardDescriptionSec3 = "styles-module--cardDescriptionSec3--tln03";
export var cardFashionWrapper = "styles-module--cardFashionWrapper--PiZF4";
export var cardImage = "styles-module--cardImage--W8f5n";
export var cardImageSec4 = "styles-module--cardImageSec4--CE3Ej";
export var cardImageSec5 = "styles-module--cardImageSec5--Hw6vB";
export var cardImageSec5a = "styles-module--cardImageSec5a--y0vSn";
export var cardImageWrapper = "styles-module--cardImageWrapper--LuBgg";
export var cardProfile = "styles-module--cardProfile--glu6v";
export var cardSec3 = "styles-module--cardSec3--YiNKo";
export var contactButton = "styles-module--contactButton--NJnTJ";
export var descriptionSec3 = "styles-module--descriptionSec3--edMMZ";
export var infoTitleSec3 = "styles-module--infoTitleSec3--4DVI5";
export var logoSec3Wrapper = "styles-module--logoSec3Wrapper--G8xvk";
export var roadmapBackground = "styles-module--roadmapBackground--MZIGV";
export var row = "styles-module--row--BUCiI";
export var rowContent = "styles-module--rowContent--AObUv";
export var section3 = "styles-module--section3--uSQaX";
export var section4Image = "styles-module--section4Image--YzZW+";
export var sectionImages = "styles-module--sectionImages--CNOxy";
export var sectionTitle = "styles-module--sectionTitle--3FiL0";
export var subTitleSec3 = "styles-module--subTitleSec3--Mxw-L";
export var subTitleSec4 = "styles-module--subTitleSec4--Kew3s";
export var titleSec3 = "styles-module--titleSec3--PKkJb";
export var titleSec3Wrapper = "styles-module--titleSec3Wrapper--Cjq9V";
export var titleSec4 = "styles-module--titleSec4--GfSmH";
export var titleSec4Wrapper = "styles-module--titleSec4Wrapper--F8x9V";
export var uppercaseWord = "styles-module--uppercaseWord--VHj+b";