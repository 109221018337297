import React, { memo } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap"
import clsx from "clsx"
import * as classes from "../../styles.module.scss"
import RelatedSearch from "../../../../components/RelatedSearch"
import { graphql } from "gatsby"
import {
  HomepageImage,
  SectionCard,
  SectionMore,
} from "../../../../components/ui"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"

export interface MainServiceSection5Props {
  id: string
  title: string
  description: string
  section5Card: SectionCard[]
  section5More: SectionMore[]
  background: HomepageImage
}

const MainServiceSection5 = memo((props: MainServiceSection5Props) => {
  return (
    <div id="section5" className="section pt-0 pb-0">
      <BackgroundImage
        className="team-3 section-image pt-0"
        {...convertToBgImage(getImage(props?.background?.gatsbyImageData))}
      >
        <Col
          className={clsx(
            "ml-auto mr-auto text-center",
            classes.titleSec4Wrapper
          )}
        >
          <h2 className={clsx("title", classes.titleSec4)}>{props?.title}</h2>
          <h4 className={clsx("description", classes.subTitleSec4)}>
            {props?.description}
          </h4>
        </Col>

        <div className="p-5">
          <Row
            className={clsx(
              "justify-content-center align-items-stretch",
              classes.row
            )}
          >
            <Col lg="4" className={classes.cardContainer}>
              <Card className="mb-0 h-100 d-flex flex-column align-items-center justify-content-stretch">
                <div className="card-image">
                  <GatsbyImage
                    className={clsx("rounded", classes.cardImageSec5a)}
                    alt={props?.section5Card[0]?.image?.alt}
                    image={getImage(
                      props?.section5Card[0]?.image?.gatsbyImageData
                    )}
                  />
                </div>
                <div
                  className={clsx(
                    "info text-center pt-0 d-flex flex-column align-items-center justify-content-center h-100",
                    classes.cardContent
                  )}
                >
                  <h4 className="info-title">
                    {props?.section5Card[0]?.title}
                  </h4>
                  <p className="description">
                    {props?.section5Card[0]?.description}
                  </p>
                </div>
              </Card>
            </Col>

            <Col className="ml-auto mr-auto" lg="8">
              <Card className="card-profile my-0">
                <Row>
                  <Col md="5">
                    <div className={clsx("card-image", classes.cardImageSec4)}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <GatsbyImage
                          className={clsx("rounded", classes.cardImage)}
                          alt={props?.section5Card[1]?.image?.alt}
                          image={getImage(
                            props?.section5Card[1]?.image?.gatsbyImageData
                          )}
                        />
                      </a>
                    </div>
                  </Col>
                  <Col
                    md="7"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <CardBody>
                      <CardTitle tag="h3" className="info-title">
                        {props?.section5Card[1]?.title}
                      </CardTitle>
                      <p className="card-description">
                        {props?.section5Card[1]?.description}
                      </p>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row className={classes.row}>
            <Col className="ml-auto mr-auto" md="10">
              <Card className={clsx("card-profile", classes.cardProfile)}>
                <Row>
                  <Col md="5">
                    <div className={clsx("card-image", classes.cardImageSec5)}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <GatsbyImage
                          className={clsx("rounded", classes.cardImage)}
                          alt={props?.section5Card[2]?.image?.alt}
                          image={getImage(
                            props?.section5Card[2]?.image?.gatsbyImageData
                          )}
                        />
                      </a>
                    </div>
                  </Col>
                  <Col
                    md="7"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <CardBody>
                      <CardTitle tag="h3" className="info-title">
                        {props?.section5Card[2]?.title}
                      </CardTitle>
                      <p className="card-description">
                        {props?.section5Card[2]?.description}
                      </p>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
              <Button className="px-5 " color="primary" href="Tel:044040044">
            <h4 className="m-0">Call Us: 04 404 0044</h4>
          </Button>
            </Col>
            
          </Row>
          
        </div>

        <RelatedSearch listItem={props?.section5More} />
      </BackgroundImage>
    </div>
  )
})

export default MainServiceSection5

export const query = graphql`
  fragment MainServiceSection5Content on MainServiceSection5 {
    id
    title
    description
    section5Card {
      id
      image {
        alt
        id
        gatsbyImageData
      }
      title
      description
    }
    section5More {
      id
      image {
        alt
        id
        gatsbyImageData
      }
      title
    }
    background {
      alt
      id
      gatsbyImageData
    }
  }
`
