import React, { memo } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card, CardBody, CardTitle, Row, Col, Container, Button } from "reactstrap"
import clsx from "clsx"
import * as classes from "../../styles.module.scss"
import { HomepageImage } from "../../../../components/ui"
import { graphql } from "gatsby"

interface Section3Card {
  id: string
  image: HomepageImage
  title: string
}

export interface MainServiceSection3Props {
  id: string
  title: string
  description: string
  section3Card: Section3Card[]
}

const MainServiceSection3 = memo((props: MainServiceSection3Props) => {
  return (
    <div id="section3" className="projects-4 pt-0" data-background-color="gray">
      <Container fluid>
        <Row className="py-3">
          <Col className="mr-auto ml-auto text-center" md="8">
            <h2 className={clsx("title", classes.sectionTitle)}>
              {props?.title}
            </h2>
            <h6 className={clsx("category text-muted", classes.uppercaseWord)}>
              {props?.description}
            </h6>
          </Col>
        </Row>

        <Row>
          <Col className="px-0" md="6">
            <Card
              className={clsx(
                "card-fashion card-background",
                classes.cardFashionWrapper
              )}
            >
              <div className={classes.cardImageWrapper}>
                <GatsbyImage
                  className="features-8 section-image py-0"
                  alt={props?.section3Card[0]?.image?.alt}
                  image={getImage(
                    props?.section3Card[0]?.image?.gatsbyImageData
                  )}
                />
              </div>
              <CardBody>
                <CardTitle className="text-center" tag="div">
                  <h2>
                    <a href="/sub-services/#section3">{props?.section3Card[0]?.title}</a>
                  </h2>
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
          <Col className="px-0" md="6">
            <div className={clsx("card-container", classes.cardContainerSec2)}>
              <Card
                className={clsx("card-fashion", classes.cardFashionWrapper)}
              >
                <CardTitle tag="div">
                  <h4>
                    <a href="/sub-services/#subServiceSection4">{props?.section3Card[1]?.title}</a>
                  </h4>
                </CardTitle>
              </Card>
              <Card className="card-fashion card-background">
                <div className={classes.cardImageWrapper}>
                  <GatsbyImage
                    className="features-8 section-image py-0"
                    alt={props?.section3Card[1]?.image?.alt}
                    image={getImage(
                      props?.section3Card[1]?.image?.gatsbyImageData
                    )}
                  />
                </div>
              </Card>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="px-0" md="6">
            <div className={clsx("card-container", classes.cardContainerSec2)}>
              <Card
                className={clsx(
                  "card-fashion card-background",
                  classes.cardFashionWrapper
                )}
              >
                <div className={classes.cardImageWrapper}>
                  <GatsbyImage
                    className="features-8 section-image py-0"
                    alt={props?.section3Card[2]?.image?.alt}
                    image={getImage(
                      props?.section3Card[2]?.image?.gatsbyImageData
                    )}
                  />
                </div>
              </Card>
              <Card className="card-fashion arrow-left">
                <CardTitle tag="div">
                  <h4>
                    <a href="/sub-services/#subServiceSection5">{props?.section3Card[2]?.title}</a>
                  </h4>
                </CardTitle>
              </Card>
            </div>
          </Col>
          <Col className="px-0" md="6">
            <Card
              className={clsx(
                "card-fashion card-background",
                classes.cardFashionWrapper
              )}
            >
              <div className={classes.cardImageWrapper}>
                <GatsbyImage
                  className="features-8 section-image py-0"
                  alt={props?.section3Card[3]?.image?.alt}
                  image={getImage(
                    props?.section3Card[3]?.image?.gatsbyImageData
                  )}
                />
              </div>
              <Button className="px-5 " color="primary" href="Tel:044040044">
            <h4 className="m-0">04 404 0044</h4>
          </Button>
              <CardBody className={classes.cardBodyItem}>
                <CardTitle className="text-center" tag="div">
                  <h2>
                    <a href="/enquiry">{props?.section3Card[3]?.title}</a>
                  </h2>
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
})

export default MainServiceSection3

export const query = graphql`
  fragment MainServiceSection3Content on MainServiceSection3 {
    id
    title
    description
    section3Card {
      id
      image {
        alt
        id
        gatsbyImageData
      }
      title
    }
  }
`
