import React, { memo } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card, Row, Col } from "reactstrap"
import clsx from "clsx"
import * as classes from "../../styles.module.scss"
import { graphql, Link } from "gatsby"
import { SectionCard } from "../../../../components/ui"

export interface MainServiceSection2Props {
  id: string
  title: string
  description: string
  section2Card: SectionCard[]
}

const MainServiceSection2 = memo((props: MainServiceSection2Props) => {
  return (
    <div
      id="section2"
      className={clsx("features-8 section-image", classes.roadmapBackground)}
    >
      <Col className="ml-auto mr-auto text-center" md="8">
        <h2 className={clsx("title", classes.uppercaseWord)}>{props?.title}</h2>
        <h4 className={clsx("description", classes.uppercaseWord)}>
          {props?.description}
        </h4>
      </Col>
      <Row className="px-5">
        <Col lg="3" className={classes.cardContainer}>
          <Card className={classes.card}>
            <div className="card-image">
              <GatsbyImage
                className={clsx("rounded", classes.cardImage)}
                alt={props?.section2Card[0]?.image?.alt}
                image={getImage(props?.section2Card[0]?.image?.gatsbyImageData)}
              />
            </div>
            <div className={clsx("info text-center", classes.cardContent)}>
              <div className="icon">
                <i className="now-ui-icons shopping_shop"></i>
              </div>
              <h4 className="info-title">
                <Link to="/sub-services/#section2">{props?.section2Card[0]?.title}</Link>
              </h4>
              <p className="description">
                {props?.section2Card[0]?.description}
              </p>
            </div>
          </Card>
        </Col>
        <Col lg="3" className={classes.cardContainer}>
          <Card className={classes.card}>
            <div className="card-image">
              <GatsbyImage
                className={clsx("rounded", classes.cardImage)}
                alt={props?.section2Card[1]?.image?.alt}
                image={getImage(props?.section2Card[1]?.image?.gatsbyImageData)}
              />
            </div>
            <div className={clsx("info text-center", classes.cardContent)}>
              <div className="icon">
                <i className="now-ui-icons business_bulb-63"></i>
              </div>
              <h4 className="info-title">
                <Link to="/main-services/#section4">
                  {props?.section2Card[1]?.title}
                </Link>
              </h4>
              <p className="description">
                {props?.section2Card[1]?.description}
              </p>
            </div>
          </Card>
        </Col>
        <Col lg="3" className={classes.cardContainer}>
          <Card className={classes.card}>
            <div className="card-image">
              <GatsbyImage
                className={clsx("rounded", classes.cardImage)}
                alt={props?.section2Card[2]?.image?.alt}
                image={getImage(props?.section2Card[2]?.image?.gatsbyImageData)}
              />
            </div>
            <div className={clsx("info text-center", classes.cardContent)}>
              <div className="icon">
                <i className="now-ui-icons business_money-coins"></i>
              </div>
              <h4 className="info-title">
                <Link to="/sub-services/#subServiceSection5">{props?.section2Card[2]?.title}</Link>
              </h4>
              <p className="description">
                {props?.section2Card[2]?.description}
              </p>
            </div>
          </Card>
        </Col>
        <Col lg="3" className={classes.cardContainer}>
          <Card className={classes.card}>
            <div className="card-image">
              <GatsbyImage
                className={clsx("rounded", classes.cardImage)}
                alt={props?.section2Card[3]?.image?.alt}
                image={getImage(props?.section2Card[3]?.image?.gatsbyImageData)}
              />
            </div>
            <div className={clsx("info text-center", classes.cardContent)}>
              <div className="icon">
                <i className="now-ui-icons ui-2_settings-90"></i>
              </div>
              <h4 className="info-title">
                <Link to="/main-services/#section5">
                  {props?.section2Card[3]?.title}
                </Link>
              </h4>
              <p className="description">
                {props?.section2Card[3]?.description}
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
})

export default MainServiceSection2

export const query = graphql`
  fragment MainServiceSection2Content on MainServiceSection2 {
    id
    title
    description
    section2Card {
      id
      image {
        alt
        id
        gatsbyImageData
      }
      title
      description
    }
  }
`
