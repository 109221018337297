import React, { memo } from "react"
import Layout from "../components/Layout/index"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import MainServiceContainer from "../containers/MainService"
import * as sections from "../containers/MainService/components/sections"

interface Props {
  data: {
    mainService: {
      id: string
      title: string
      image: { id: string; url: string }
      blocks: sections.MainServiceBlock[]
    }
  }
}

const MainService = memo((props: Props) => {
  const { mainService } = props.data

  return (
    <Layout title="Main Services">
      <SEO title="Main Services" />
      <MainServiceContainer blocks={mainService?.blocks} />
    </Layout>
  )
})

export default MainService

export const query = graphql`
  {
    mainService {
      id
      title
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...MainServiceSection1Content
        ...MainServiceSection2Content
        ...MainServiceSection3Content
        ...MainServiceSection4Content
        ...MainServiceSection5Content
      }
    }
  }
`
