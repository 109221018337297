import React, { Fragment, memo } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { HomepageImage } from "../../../../components/ui"

export interface MainServiceSection1Props {
  id: string
  background: HomepageImage
}

const MainServiceSection1 = memo((props: MainServiceSection1Props) => {
  return (
    <Fragment>
      <div id="section1" className="page-header page-header-small">
        <div className="page-header-image">
          <GatsbyImage
            objectFit="cover"
            objectPosition={"center"}
            style={{ width: "100%", height: "100%" }}
            alt={props?.background.alt}
            image={getImage(props?.background.gatsbyImageData)}
          />
        </div>
      </div>
    </Fragment>
  )
})

export default MainServiceSection1

export const query = graphql`
  fragment MainServiceSection1Content on MainServiceSection1 {
    id
    background {
      alt
      id
      gatsbyImageData
    }
  }
`
